import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValueCaptureSlider } from '@/valueCapture/ValueCaptureSlider.atom';
import { FormElement } from '@/formbuilder/formBuilder.constants';
import { ReportContentWorksheetAndUrl } from '@/reportEditor/components/reportContentModal/shared/ReportContentWorksheetAndUrl.molecule';
import { SelectWorksheet } from '@/worksheets/SelectWorksheet.molecule';
import { Button, Icon } from '@seeqdev/qomponents';
import _ from 'lodash';

import {
  availableStages,
  IMPACT_OWNER_FIRSTNAME,
  IMPACT_OWNER_LASTNAME,
  ImpactType,
  instructions,
  quantifyUnits,
  stageIcons,
  Stages,
  TRACK_IMPACT,
  VALUE_CAPTURE_LABEL_ID,
  ValueCaptureCategory,
} from '@/valueCapture/valueCapture.types';
import { sqContextApi } from '@/sdk';

import { sqValueCaptureStore, sqWorkbenchStore, sqWorkbookStore } from '@/core/core.stores';
import { SaveAndContinueFormBuilder } from '@/formbuilder/SaveAndContinueFormBuilder.page';
import { IdentityPreviewV1 } from '@/sdk/model/IdentityPreviewV1';
import { useAsyncEffect } from 'rooks';
import { randomUUID } from '@/utilities/utilities';
import {
  addOrUpdateImpactReport,
  getCustomCategoryTitle,
  getValueCaptureTableId,
  stageDisplayStringByIndex,
} from '@/valueCapture/valueCapture.utilities';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { ScalarPropertyV1 } from '@/sdk/model/ScalarPropertyV1';
import { SelectedSearchItem } from '@/search/search.types';
import { ManageCustomCategoriesModal } from '@/valueCapture/ManageCustomCategoriesModal.molecule';
import { isAdmin } from '@/services/authorization.service';
import { SelectedWorksheetDisplayAndLink } from '@/valueCapture/SelectedWorksheetDisplayAndLink.atom';
import { doTrack } from '@/track/track.service';
import { ContextLabelWithIdInputV1 } from 'sdk/model/ContextLabelWithIdInputV1';

interface ValueCaptureModalProps {
  existingReport?: string;
  stageIndex?: number;
  workbookId?: string;
  worksheetId?: string;
  setWorkbookId: (id: string) => void;
  setWorksheetId: (id: string) => void;
  closeFn: (reloadTable?: boolean) => void;
  setStageIndex: (idx: number) => void;
  report?: Record<string, any>;
  categories?: ValueCaptureCategory[];
}

interface ValueCaptureHeaderProps {
  workbookId: string;
  worksheetId: string;
  stageIndex: number;
  setStageIndex: (idx: number) => void;
  setWorkbookId: (id: string) => void;
  setWorksheetId: (id: string) => void;
}

export const ValueCaptureHeader: React.FunctionComponent<ValueCaptureHeaderProps> = ({
  workbookId,
  worksheetId,
  stageIndex = 0,
  setStageIndex,
  setWorksheetId,
  setWorkbookId,
}) => {
  const { t } = useTranslation();
  const [currentStage, setCurrentStage] = useState<Stages | null>(null);
  const [currentStageDisplay, setCurrentStageDisplay] = useState('');

  useEffect(() => {
    setCurrentStage(availableStages[stageIndex]);
    setCurrentStageDisplay(t(`VALUE_CAPTURE.${availableStages[stageIndex]?.toUpperCase()}`));
  }, [stageIndex]);

  return (
    <div className="flexRowContainer valueCapture width-maximum">
      <div className="mb5">
        <h4>{t('VALUE_CAPTURE.CAPTURE_IMPACT')}</h4>
      </div>
      <div className="flexColumnContainer flexWrap">
        {workbookId && worksheetId ? (
          <div className="flexRowContainer mt0 flexAlignStart flexFill">
            <div>{t('VALUE_CAPTURE.SELECTED_WORKSHEET')}</div>
            <div>
              <SelectedWorksheetDisplayAndLink workbookId={workbookId} worksheetId={worksheetId} />
              <Icon
                icon="fa-close"
                onClick={() => {
                  setWorkbookId('');
                  setWorksheetId('');
                  setStageIndex(0);
                }}
                extraClassNames="ml10 mt3 cursorPointer"
              />
            </div>
          </div>
        ) : (
          <div className="flexFill" />
        )}
        {currentStage && (
          <div className="flexRowContainer flexAlignEnd">
            <h6 className="mtn8">
              {t('VALUE_CAPTURE.CURRENT_STAGE')} {currentStageDisplay}
            </h6>
            <ValueCaptureSlider setStageIdx={setStageIndex} stageIndex={stageIndex} />
          </div>
        )}
      </div>
    </div>
  );
};

export const ValueCaptureForm: React.FunctionComponent<ValueCaptureModalProps> = ({
  stageIndex = 0,
  workbookId,
  worksheetId,
  setWorkbookId,
  setWorksheetId,
  setStageIndex,
  closeFn,
  report,
  categories,
}) => {
  const isReport = useFluxPath(sqWorkbookStore, () => sqWorkbookStore.isReportBinder);
  const { t } = useTranslation();
  const [impactType, setImpactType] = useState<ImpactType>('oneTime');
  const [rowDatumId, setRowDatumId] = useState<string | undefined>(
    report && report[SeeqNames.MaterializedTables.DatumIdColumn],
  );

  // we don't need a store, but we need to store the values in local state to ensure we can persist them across stages
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [owner, setOwner] = useState({
    id: sqWorkbenchStore.currentUser.id,
    name: sqWorkbenchStore.currentUser.name,
  });
  const [customCategories, setCustomCategories] = useState<{ [key: string]: any }>({});

  const [asset, setAsset] = useState('');
  const [selectUnit, setSelectUnit] = useState('');
  const [lessonsLearned, setLessonsLearned] = useState('');
  const [impactStartDate, setImpactStartDate] = useState<Date | undefined>(undefined);
  const [impactEndDate, setImpactEndDate] = useState<Date | undefined>(undefined);
  const [impactAmount, setImpactAmount] = useState('');
  const [quantifyAmount, setQuantifyAmount] = useState('100');
  const [impactFrequency, setImpactFrequency] = useState('');
  const [calcItem, setCalcItem] = useState<SelectedSearchItem | undefined>(undefined);
  const [isReportBinder, setIsReportBinder] = useState(isReport && window.location.pathname !== '/value');

  const [site, setSite] = useState(sqWorkbenchStore.currentUser.site);

  const tableId = useFluxPath(sqValueCaptureStore, () => sqValueCaptureStore.tableId);

  useAsyncEffect(async () => {
    await getValueCaptureTableId();
  }, []);

  useEffect(() => {
    if (!report || _.isEmpty(report)) return;
    setAsset(report[SeeqNames.ValueCapture.ImpactAsset]);
    setTitle(report[SeeqNames.ValueCapture.ImpactName]);
    setDescription(report[SeeqNames.ValueCapture.ImpactDescription]);
    setImpactEndDate(
      report[SeeqNames.ValueCapture.ImpactEndDate] && new Date(report[SeeqNames.ValueCapture.ImpactEndDate]),
    );
    setImpactStartDate(
      report[SeeqNames.ValueCapture.ImpactStartDate] && new Date(report[SeeqNames.ValueCapture.ImpactStartDate]),
    );
    setSelectUnit(report[SeeqNames.ValueCapture.ImpactUnit] as string);
    setCalcItem({ id: report[SeeqNames.ValueCapture.ImpactFormulaParam] });
    setImpactFrequency(report[SeeqNames.ValueCapture.ImpactFrequency]);
    setImpactAmount(report[SeeqNames.ValueCapture.Impact]);
    setQuantifyAmount(report[SeeqNames.ValueCapture.ImpactQuantifyAmount]);
    setWorksheetId(report[SeeqNames.ValueCapture.AssignedToWorksheet]);
    setWorkbookId(report[SeeqNames.ValueCapture.AssignedToWorkbook]);
    setImpactType(report[SeeqNames.ValueCapture.ImpactSavingsType]);
    setSite(report[SeeqNames.ValueCapture.ImpactSite]);
    setOwner({
      id: report[SeeqNames.ValueCapture.ImpactOwner],
      name: `${report[IMPACT_OWNER_FIRSTNAME]} ${report[IMPACT_OWNER_LASTNAME]}`,
    } as IdentityPreviewV1);
    setLessonsLearned(report[SeeqNames.ValueCapture.ImpactLessonsLearned]);
    const customCats: Record<string, string>[] | undefined =
      categories?.map((category) => {
        const cat = category.category;
        return { [cat.id!]: report[cat.id!] };
      }) || [];
    setCustomCategories(customCats);
  }, [report]);

  const sectionHeader = (stageIndex: number) => (
    <>
      <div className={`flexColumnContainer flexAlignCenter mbn16 formSectionHeader ${stageIndex !== 0 ? 'mt20' : ''}`}>
        <Icon icon={stageIcons[stageIndex]} extraClassNames="mr10 ml5" />
        <h4 className="flexFill">{stageDisplayStringByIndex(stageIndex)}</h4>
        <div className="mr5">
          {stageIndex === 1 && isAdmin() && <ManageCustomCategoriesModal categories={categories} />}
        </div>
      </div>
      <hr />
    </>
  );

  if (!tableId) return null;

  const categoryFields = categories
    ? categories.map((category) => {
        const cat = category.category;
        const fieldName = getCustomCategoryTitle(category);
        const options = category.options;
        const categoryId = cat.id!;
        return {
          label: fieldName,
          component: 'SelectFormComponent',
          name: categoryId,
          value: customCategories[categoryId] ? customCategories[categoryId] : report && report[categoryId],
          required: false,
          options: options as any,
          onChange: (value) => {
            setCustomCategories({ ...customCategories, [categoryId]: value });
          },
        } as FormElement;
      })
    : [];

  const formDefinition: FormElement[] = [
    {
      component: 'FormGroup',
      name: 'identify',
      includeIf: stageIndex >= 0,
      components: [
        {
          component: 'DisplayOnlyFormElementWrapper',
          name: 'identifyHeader',
          children: sectionHeader(0),
        },
        {
          component: 'TextFieldFormComponent',
          name: 'title',
          label: 'Title',
          value: title,
          required: true,
          onChange: (title) => {
            setTitle(title as string);
          },
          size: 'sm',
          testId: 'title',
        },
        {
          component: 'CKEditorFormComponent',
          name: 'description',
          label: t('VALUE_CAPTURE.DESCRIPTION'),
          extraClassNames: 'width-maximum',
          value: description ? description : instructions,
          required: true,
          onChange: (description) => {
            setDescription(description as string);
          },
          testId: 'description',
          placeholder:
            'The Description field should clearly describe the technical problem or challenge your team is facing. This may include issues with systems, processes, or equipment. Be as specific as possible, focusing on the following points:\n' +
            '\n' +
            'Problem Overview: Provide a concise explanation of the issue you are trying to solve. Mention the system, process, or equipment involved, and explain the challenge (e.g., pressure fluctuations, overloads, nuisance alarms).\n' +
            '\n' +
            'Impact: Describe the potential or actual negative impact of the issue. This could include safety risks, financial costs, production delays, or regulatory concerns.\n' +
            '\n' +
            'Specific Technical Details: Include any technical metrics or specifications related to the issue (e.g., pressure thresholds, system overloads, number of alarms, temperature limits). Be as detailed as possible so the problem is clearly understood.\n' +
            '\n' +
            'Action Items: Outline the steps or actions that will be taken to address the technical challenge. Specify who is responsible for each action, deadlines, and any tools or resources required for resolution or implementation.',
        },
        {
          component: 'LabelFormComponent',
          name: 'ownerLabel',
          value: 'Use Case Owner',
          testId: 'ownerLabel',
          extraClassNames: '',
        },
        {
          component: 'SelectIdentityFormComponent',
          name: 'owner',
          idForLabel: 'useCaseOwner',
          label: 'Use Case Owner',
          extraClassNames: 'width-maximum mb25',
          value: owner,
          required: true,
          startEditable: true,
          onChange: (selectedIdentity) => {
            setOwner(_.pick(selectedIdentity, ['id', 'name']) as IdentityPreviewV1);
          },
          testId: 'owner',
        },
      ],
    },
    {
      component: 'FormGroup',
      name: 'categorize',
      includeIf: stageIndex >= 1,
      components: [
        {
          component: 'DisplayOnlyFormElementWrapper',
          name: 'categoryHeader',
          children: sectionHeader(1),
        },
        {
          component: 'TextFieldFormComponent',
          name: 'site',
          label: 'Site',
          value: site,
          onChange: (site) => {
            setSite(site);
          },
          size: 'sm',
          testId: 'site',
        },
        ...categoryFields,
        {
          component: 'TextFieldFormComponent',
          name: 'asset',
          label: 'Asset Type',
          value: asset,
          onChange: (asset) => {
            setAsset(asset as string);
          },
          size: 'sm',
          testId: 'title',
        },
      ],
    },
    {
      component: 'FormGroup',
      name: 'quantify',
      includeIf: stageIndex >= 2,

      components: [
        {
          component: 'DisplayOnlyFormElementWrapper',
          name: 'quantifyHeader',
          children: sectionHeader(2),
        },
        {
          component: 'CKEditorFormComponent',
          name: 'lessonsLearned',
          label: t('VALUE_CAPTURE.LESSONS_LEARNED'),
          extraClassNames: 'width-maximum',
          value: lessonsLearned,
          placeholder:
            'What other assets or sites would this be applicable for? Are there particular gotchas or things to be aware of if someone tried to replicate this? Did the changes require any coordination with other departments?',
          onChange: (lessons: string) => {
            setLessonsLearned(lessons);
          },
          testId: 'lessonsLearned',
        },
        {
          component: 'LabelFormComponent',
          name: 'quantifyLabel',
          value: 'Order of Magnitude Direct Impact Estimate (1000s of gallons of water per month)',
          testId: 'quantifyLabel',
        },
        {
          component: 'FormRow',
          name: 'quantifyRow',
          extraClassNames: 'flexAlignCenter',
          components: [
            {
              extraClassNames: 'width-200',
              component: 'SelectFormComponent',
              name: 'quantifyAmount',
              value: quantifyAmount,
              required: true,
              options: [
                { value: '100', label: '100s' },
                { value: '1000', label: '1000s' },
                { value: '10000', label: '10.000s' },
                { value: '100000', label: '100.000s' },
                { value: '1000000', label: '1.000.000' },
              ],
              onChange: (selection) => {
                setQuantifyAmount(selection as string);
              },
            },
            {
              component: 'LabelFormComponent',
              name: 'quantifyLabel',
              value: 'of',
              testId: 'quantifyLabel',
              extraClassNames: 'ml5 mr5',
            },
            {
              component: 'SelectUnitFormComponent',
              name: 'quantifyUnit',
              value: selectUnit,
              onChange: (selection) => {
                setSelectUnit(selection as string);
              },
              required: true,
              units: quantifyUnits,
            },
          ],
        },
        {
          component: 'CheckboxFormComponent',
          name: 'oneTime',
          type: 'radio',
          value: impactType === 'oneTime',
          onChange: () => {
            setImpactType('oneTime');
          },
          checkboxLabel: 'one time impact',
          id: 'oneTime',
          testId: 'oneTime',
          skipStore: true,
        },
        {
          component: 'CheckboxFormComponent',
          name: 'continuous',
          type: 'radio',
          value: impactType === 'continuous',
          onChange: () => {
            setImpactType('continuous');
          },
          checkboxLabel: 'continuous impact',
          id: 'continuous',
          testId: 'continuous',
        },
        {
          component: 'CheckboxFormComponent',
          name: 'customCalc',
          type: 'radio',
          value: impactType === 'customCalc',
          onChange: () => {
            setImpactType('customCalc');
          },
          checkboxLabel: 'custom calculation',
          id: 'customCalc',
          testId: 'customCalc',
        },
      ],
    },
    {
      component: 'FormGroup',
      name: 'continuous',
      includeIf: stageIndex >= 2,
      components: [
        {
          component: 'FormRow',
          name: 'startEndRow',
          extraClassNames: 'flexAlignCenter',
          components: [
            {
              component: 'DatePickerFormComponent',
              name: 'impactStartDate',
              label: t('VALUE_CAPTURE.START_DATE'),
              value: impactStartDate,
              required: true,
              onChange: (date) => {
                setImpactStartDate(date);
              },
              testId: 'impactStartDate',
              wrapperClassNames: 'mr5',
              clearDate: () => setImpactStartDate(undefined),
            },
            {
              component: 'DatePickerFormComponent',
              name: 'impactEndDate',
              includeIf: impactType === 'continuous' || impactType === 'customCalc',
              label: t('VALUE_CAPTURE.END_DATE'),
              value: impactEndDate,
              onChange: (date: Date) => {
                setImpactEndDate(date);
              },
              testId: 'impactEndDate',
              wrapperClassNames: 'ml5',
              clearDate: () => setImpactEndDate(undefined),
            },
          ],
        },
      ],
    },
    {
      component: 'FormGroup',
      name: 'monetize',
      includeIf: stageIndex >= 3,
      components: [
        {
          component: 'DisplayOnlyFormElementWrapper',
          name: 'monetizeHeader',
          children: sectionHeader(3),
        },
        {
          component: 'SelectItemSearchFormComponent',
          includeIf: impactType === 'customCalc' && stageIndex >= 3,
          name: 'customCalcItem',
          value: calcItem?.id ? [calcItem] : undefined,
          title: t('VALUE_CAPTURE.FIND_CUSTOM_CALC'),
          isMultiple: false,
          searchTypes: ['Signal'],
          notSelectedMessage: t('VALUE_CAPTURE.FIND_CUSTOM_CALC'),
          iconPartialTooltipKey: 'customCalc',
          overrideWorkbookId: workbookId,
          onChange: (item) => {
            item?.length === 1 ? setCalcItem(item?.[0]) : setCalcItem(undefined);
          },
        },
        {
          component: 'FormRow',
          name: 'monetizeFormRow',
          includeIf: impactType === 'oneTime' || impactType === 'continuous',
          components: [
            {
              component: 'TextFieldFormComponent',
              label: 'Amount in ($)',
              name: 'impactAmount',
              value: impactAmount,
              size: 'sm',
              onChange: (amount) => {
                setImpactAmount(amount as string);
              },
              testId: 'impactAmount',
              wrapperClassNames: 'mr5',
              required: true,
            },
            {
              component: 'FormGroup',
              name: 'continuousImpact',
              includeIf: impactType === 'continuous',
              extraClassNames: 'mb20',
              components: [
                {
                  label: t('VALUE_CAPTURE.FREQUENCY'),
                  component: 'SelectFormComponent',
                  name: 'impactFrequency',
                  value: impactFrequency,
                  options: [
                    { value: '1', label: 'daily' },
                    { value: '7', label: 'weekly' },
                    { value: '30.5', label: 'monthly' },
                    { value: '90', label: 'quarterly' },
                    { value: '365', label: 'yearly' },
                  ],
                  onChange: (frequency) => {
                    setImpactFrequency(frequency as string);
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const onFormSubmitCallback = async (values: any) => {
    // we need a dummy formula so we can successfully create the condition
    let formula =
      "condition(10y, capsule('1971-01-01T00:00:00.00Z'," +
      " '1971-01-01T00:00:00.00Z').setProperty('Impact Estimate', 0))";

    const startDate = impactStartDate && (impactStartDate as Date)?.toISOString();
    const endDate = impactEndDate && (impactEndDate as Date)?.toISOString();

    if (startDate && impactAmount) {
      if (impactType === 'continuous') {
        if (!impactEndDate) {
          formula = `(condition(10y,capsule('${startDate}', now())).setUncertainty(1h)).intersect(days().setProperty('Impact Estimate',${impactAmount}/${impactFrequency}),keepProperties())`;
        } else {
          formula = `(condition(10y,capsule('${startDate}','${endDate}'))).intersect(past()).intersect(days().setProperty('Impact Estimate',${impactAmount}/${impactFrequency}),keepProperties()).setUncertainty(1h)`;
        }
      } else if (impactType === 'oneTime') {
        formula = `condition(10y,capsule('${startDate}', '${startDate}').setProperty('Impact Estimate', ${impactAmount}))`;
      }
    }
    if (impactType === 'customCalc' && calcItem) {
      formula =
        '($customSavingsSignal *' +
        " ($customSavingsSignal.toCapsules().aggregate(totalDuration(\"s\"),$customSavingsSignal.toCapsules(), startKey()))).reduceUnits().tocapsules().renameProperty('value','Impact Estimate')";
    }

    const properties: Array<ScalarPropertyV1> = [];
    const addIfNotEmpty = (name: string, value: string | boolean | null | undefined) => {
      if (value) {
        properties.push({ name, value });
      }
    };
    addIfNotEmpty(SeeqNames.ValueCapture.ImpactName, title);
    addIfNotEmpty(SeeqNames.ValueCapture.ImpactDescription, description);
    addIfNotEmpty(SeeqNames.ValueCapture.ImpactOwner, owner?.id);

    addIfNotEmpty(SeeqNames.ValueCapture.ImpactSite, site);
    addIfNotEmpty(SeeqNames.ValueCapture.ImpactAsset, asset);

    addIfNotEmpty(SeeqNames.ValueCapture.ImpactLessonsLearned, lessonsLearned);
    addIfNotEmpty(SeeqNames.ValueCapture.ImpactQuantifyAmount, quantifyAmount);
    addIfNotEmpty(SeeqNames.ValueCapture.ImpactSavingsType, impactType);
    addIfNotEmpty(SeeqNames.ValueCapture.ImpactUnit, selectUnit);
    addIfNotEmpty(SeeqNames.ValueCapture.ImpactStartDate, impactStartDate && impactStartDate?.toISOString());
    addIfNotEmpty(SeeqNames.ValueCapture.ImpactEndDate, impactEndDate && impactEndDate?.toISOString());

    addIfNotEmpty(SeeqNames.ValueCapture.ImpactFrequency, impactFrequency);

    addIfNotEmpty(SeeqNames.ValueCapture.Impact, impactAmount);
    addIfNotEmpty(SeeqNames.ValueCapture.ImpactFormula, formula);
    addIfNotEmpty(SeeqNames.ValueCapture.ImpactFormulaParam, calcItem?.id);

    addIfNotEmpty(SeeqNames.ValueCapture.AssignedToWorksheet, worksheetId);
    addIfNotEmpty(SeeqNames.ValueCapture.AssignedToWorkbook, workbookId);
    addIfNotEmpty(SeeqNames.ValueCapture.ImpactStatus, availableStages[stageIndex]);

    // if we are updating an existing report, we need to use the existing datum id
    let useThisDatumId = rowDatumId;
    if (useThisDatumId === undefined) {
      doTrack(TRACK_IMPACT, 'Created new Impact Report', { isReportBinder, impactType });
      useThisDatumId = randomUUID();
    } else {
      doTrack(TRACK_IMPACT, 'Updated existing Impact Report', { isReportBinder, impactType });
    }

    properties.push({ name: SeeqNames.MaterializedTables.ItemIdColumn, value: tableId });
    properties.push({ name: SeeqNames.MaterializedTables.DatumIdColumn, value: useThisDatumId });

    /**
     * TODO: CRAB-46894:
     * As part of CRAB-46894 please remove the next line of code and ensure that the datum_id column rules are
     * removed so that a simple "datum id" property is used as the value of the datum id.
     *
     *
     * How we got here:
     * Due to the lack of a table type for Materialized Tables a migration that changes the datum_id to be expecting
     * a property named "Existing Row Identifier" is applied to the ImpactReport Table. To ensure updates of impact
     * reports work as expected we need to also include a property called "Existing Row Identifier" with our Events.
     * The value is simple the datum id of the row we are updating (as it's still being returned as the datum id).
     */
    properties.push({ name: SeeqNames.MaterializedTables.UniqueRowId, value: useThisDatumId });

    // remove all previously assigned context labels to avoid problems
    if (categories) {
      const contextLabels: ContextLabelWithIdInputV1[] = _.chain(categories)
        .map((category: ValueCaptureCategory) => {
          const labelId = values[category.category.id!];
          return labelId
            ? {
                contextLabelId: report && report[`${category.category.id}_${VALUE_CAPTURE_LABEL_ID}`],
                datumId: useThisDatumId,
                labelId,
              }
            : null;
        })
        .compact()
        .value();
      try {
        await sqContextApi.batchCreateContextLabels(contextLabels, { itemId: tableId });
      } catch (err) {
        console.log(err);
      }
    }

    await addOrUpdateImpactReport(tableId, properties);
    setRowDatumId(useThisDatumId); // ensure this gets set so the "continue" button works
  };

  return (
    <div className="flexRowContainer flexAlignCenter mb10 flexFill valueCapture">
      {!workbookId && !worksheetId && (
        <div className="flexRowContainer mt15 flexFill width-maximum flexAlignCenter">
          <div className="flexColumnContainer flexAlignCenter mbn16 formSectionHeader width-maximum mb10">
            <h4 className="ml10">{t('VALUE_CAPTURE.SELECT_WORKSHEET_AND_WORKBOOK')}</h4>
          </div>
          <hr />
          <ReportContentWorksheetAndUrl
            selectOnlyWorkbooks={true}
            allowedTypes={[SeeqNames.Types.Topic, SeeqNames.Types.Analysis, SeeqNames.Types.Folder]}
            extraClassNames="width-maximum"
            setSelectedItem={(item) => {
              setWorkbookId(item?.id);
            }}
          />
          <Button label={t('VALUE_CAPTURE.CONTINUE')} disabled={!workbookId} extraClassNames="mt10" />
        </div>
      )}
      {workbookId && !worksheetId && (
        <div className="flexRowContainer mt15 flexFill flexAlignCenter">
          <div className="flexColumnContainer flexAlignCenter mbn16 formSectionHeader width-maximum mb10">
            <h4 className="ml10">{t('VALUE_CAPTURE.SELECT_WORKSHEET')}</h4>
          </div>
          <hr />
          <SelectWorksheet
            workbookId={workbookId}
            worksheetIds={[]}
            setWorksheetIds={(worksheetIds) => {
              if (worksheetIds?.length > 0) setWorksheetId(worksheetIds[0]);
            }}
            workstepIds={[]}
            setWorkstepIds={() => {}}
            setTotalWorksheets={() => {}}
            isLoading={false}
          />
          <Button label={t('VALUE_CAPTURE.CONTINUE')} disabled={!worksheetId} extraClassNames="mt10" />
        </div>
      )}

      {workbookId && worksheetId && (
        <>
          <SaveAndContinueFormBuilder
            submitBtnLabel="Save & Close"
            extraClassNames="flexFill overflow-auto height-maximum"
            continueButtonLabel="Save & Continue"
            continueButtonPosition="left"
            continueButtonExtraClassNames="mr10"
            continueButtonEnabled={stageIndex < availableStages.length - 1}
            continueButtonOnSubmit={async (values) => {
              await onFormSubmitCallback(values);
              if (stageIndex < availableStages.length - 1) {
                setStageIndex(stageIndex + 1);
              }
              doTrack(TRACK_IMPACT, 'Save and Continue Button', { stageIndex });
            }}
            continueButtonVariant="theme"
            formDefinition={formDefinition}
            submitFn={async (values) => {
              await onFormSubmitCallback(values);
              setRowDatumId(undefined);
              closeFn(true);
              doTrack(TRACK_IMPACT, 'Save and Close Button', { stageIndex });
            }}
            closeFn={closeFn}
          />
        </>
      )}
    </div>
  );
};
